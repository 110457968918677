import {StorageEngine} from "@ngxs/storage-plugin";
import {Preferences} from "@capacitor/preferences";

export class PersistentStorageEngine implements StorageEngine {
  get length(): number {
    // Your logic here
    return 1;
  }

  getItem(key: string): any {
    // const ret = await Preferences.get({key});
    // console.log("getItem", ret.value);
    // return ret.value;

    return localStorage.getItem(key);
  }

  setItem(key: string, val: any): any {
    localStorage.setItem(key, val);

    // Preferences.set({
    //  key,
    //  value: val
    // });
  }

  removeItem(key: string): void {
    // Your logic here
  }

  clear(): void {
    // Your logic here
  }
}
