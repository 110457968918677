import {inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BandmanagerRestApiAuthenticationService } from '@digitale-menschen/bandmanager-rest-api';
import {Store} from "@ngxs/store";
import {UserStateActions} from "../../shared/user-state/user-state.actions";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  static onLogout$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isRefreshing = false;
  private store = inject(Store);

  constructor(
    private readonly bandmanagerRestApiAuthenticationService: BandmanagerRestApiAuthenticationService,
  ) {
  }

  /**
   * Intercepts every HTTP request. It will turn on 'withCredentials' so that cookies are being sent to the API.
   * If a http 401 exception occurs, it will attempt to get new access and refresh token.
   * If this fails, it will update the onLogout$ subject which can be used in the frontend to e.g. re-route, clear state etc.
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          /**
           * Logout the user if the 401 error stems from refresh token endpoints (=> refresh token has expired)
           */
          if (request.url.indexOf(BandmanagerRestApiAuthenticationService.AuthenticationControllerRefreshTokenPath) > -1) {
            this.store.dispatch(new UserStateActions.SetIsLoggedIn(false));
            ApiInterceptor.onLogout$.next(true);
          }
          return this.handle401Error(request, next);
        } else {
          throw error;
        }

        // TODO: create account,
        // 409: "Es existiert bereits ein Account mit dieser E-Mail Adresse."
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      /**
       * Get new access_token and refresh_token based on the current refresh_token.
       */
      return this.bandmanagerRestApiAuthenticationService.authenticationControllerRefreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          return next.handle(request);
        }),
        catchError(error => {
          throw error;
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
