import {Component, inject, NgZone} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import {environment} from "../environments/environment";
import {CustomMissingTranslationHandler} from "./classes/CustomMissingTranslationHandler.class";
import {MainMenuComponent} from "./components/main-menu/main-menu.component";
import { App, URLOpenListenerEvent } from '@capacitor/app';

/**
 * Create a custom translation loader
 * @param httpClient
 * @returns {TranslateHttpLoader}
 */
export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.translation_path, '.json');
}

/**
 * Factory for CustomMissingTranslationHandler
 *
 * @returns {CustomMissingTranslationHandler}
 */
export function createCustomMissingTranslationHandler() {
  return new CustomMissingTranslationHandler();
}

@Component({
  selector: 'app-root',
  standalone: true,
    imports: [RouterOutlet, MainMenuComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'bandmanager-app';
  private translateService = inject(TranslateService);
  private zone = inject(NgZone);
  private router = inject(Router);

  constructor() {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    registerLocaleData(localeDe);

    this.initListeners();
  }

  protected initListeners(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".app").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }
}
