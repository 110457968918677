import {Routes} from '@angular/router';
import {AuthGuardService} from "./services/auth-guard.service";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./pages/page-dashboard/page-dashboard.component').then(m => m.PageDashboardComponent),
  },
  {
    path: "auth",
    children: [
      {
        path: 'login',
        loadComponent: () => import('./pages/authentication/page-login/page-login.component').then(m => m.PageLoginComponent),
      },
      {
        path: 'create-account',
        loadComponent: () => import('./pages/authentication/page-create-account/page-create-account.component').then(m => m.PageCreateAccountComponent),
      },
      {
        path: 'verify',
        loadComponent: () => import('./pages/authentication/page-verify/page-verify.component').then(m => m.PageVerifyComponent),
      },
      {
        path: 'username',
        loadComponent: () => import('./pages/authentication/page-username/page-username.component').then(m => m.PageUsernameComponent),
      },
    ]
  },
  {
    path: "setup",
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/setup/page-create-band/page-create-band.component').then(m => m.PageCreateBandComponent),
      },
      {
        path: 'logo',
        loadComponent: () => import('./pages/setup/page-logo/page-logo.component').then(m => m.PageLogoComponent),
      },
      {
        path: 'uniform',
        loadComponent: () => import('./pages/setup/page-uniform/page-uniform.component').then(m => m.PageUniformComponent),
      },
      {
        path: 'uniform/part/:partId',
        loadComponent: () => import('./pages/setup/page-uniform-part/page-uniform-part.component').then(m => m.PageUniformPartComponent),
      },
    ]
  },
  {
    path: 'bandroom',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/bandroom/page-bandroom/page-bandroom.component').then(m => m.PageBandroomComponent),
      },
      {
        path: 'instruments',
        loadComponent: () => import('./pages/bandroom/page-instruments/page-instruments.component').then(m => m.PageInstrumentsComponent),
      },
      {
        path: 'players',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/bandroom/page-players/page-players.component').then(m => m.PagePlayersComponent),
          },
          {
            path: 'details/:id',
            loadComponent: () => import('./pages/bandroom/page-player-details/page-player-details.component').then(m => m.PagePlayerDetailsComponent),
          }
        ]
      }
    ]
  },
  {
    path: 'events',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./pages/page-events/page-events.component').then(m => m.PageEventsComponent),
  },
  {
    path: 'ranking',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./pages/page-ranking/page-ranking.component').then(m => m.PageRankingComponent),
  },
];
