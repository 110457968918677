import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { ApiModule } from '@digitale-menschen/bandmanager-rest-api';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createCustomMissingTranslationHandler, createTranslateLoader } from './app.component';
import { NgxsModule } from '@ngxs/store';
import { UserState } from '../shared/user-state/user.state';
import { ApiInterceptor } from './services/api-interceptor.service';
import {NgxsStoragePluginModule, STORAGE_ENGINE} from "@ngxs/storage-plugin";
import {PersistentStorageEngine} from "./classes/persistent-storage-engine.class";
import {BandState} from "../shared/band-state/band.state";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    // todo: provideHttpClient(withInterceptors([CustomHttpErrorInterceptor])),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useFactory: createCustomMissingTranslationHandler,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      ApiModule.forRoot({
        rootUrl: environment.apiConfiguration.baseUrl,
      }),
      NgxsModule.forRoot(
        [UserState, BandState],
        {
          // developmentMode: !environment.production,
          selectorOptions: {
            suppressErrors: false,
            injectContainerState: false,
          },
        },
      ),
      NgxsStoragePluginModule.forRoot({keys:['UserState', 'BandState']})
    ),
    {
      provide: LOCALE_ID,
      useValue: 'en-EN',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: PersistentStorageEngine
    }
  ],
};
